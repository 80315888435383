import React, { useState } from "react";

import Header from "../components/header";

import LoadingSpinner from "../components/loadingSprinner";
import AlertComponent from "../components/alert";

import { useBackend } from "../context/backendContext";
import GMap from "../components/gmap";

function ContactsRoute(props) {
  let alertInterval;
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");
  const [alertText, setAlertText] = useState("Alert alert Alert!");

  const [showSpinner, setShowSpinner] = useState(false);

  const handleShowAlert = (message, variant, time) => {
    setAlertVariant(variant);
    setAlertText(message);
    setShowAlert(true);
    alertInterval = setInterval(() => {
      setShowAlert(false);
      clearInterval(alertInterval);
    }, time * 1000);
  };

  const [firstName, setFirstName] = useState("");
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const [lastName, setLastName] = useState("");
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const [email, setEmail] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const [phone, setPhone] = useState("");
  const handlePhoneChange = (e) => {
    if (!isNaN(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  const [message, setMessage] = useState("");
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const { sendContactForm } = useBackend();
  const handleContactFormSubmit = async () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      phone === "" ||
      message === ""
    ) {
      handleShowAlert(
        "Llene todos los campos del formulario antes de enviar.",
        "warning",
        3
      );
      return;
    }

    const dataMessage = {
      name: firstName,
      lastname: lastName,
      email: email,
      phone: phone,
      message: message,
    };
    try {
      setShowSpinner(true);
      const backendResponse = await sendContactForm(dataMessage);
      setShowSpinner(false);
      handleShowAlert(backendResponse["message"], "success", 3);
      resetFormFields();
    } catch (error) {
      setShowSpinner(false);
      handleShowAlert(error.message, "danger", 3);
    }
  };

  function resetFormFields() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
  }

  // const [width, setWidth] = useState(0);
  // const [height, setHeight] = useState(0);
  // const [wFactor, setWFactor] = useState(0);
  // const [hFactor, setHFactor] = useState(0);

  // useEffect(() => {
  //   function handleWindowResize() {
  //     if (window.innerWidth >= 1160) {
  //       setWFactor(0.45);
  //       setHFactor(1);
  //     } else if (window.innerWidth >= 660) {
  //       setWFactor(0.92);
  //       setHFactor(0.95 * 0.8);
  //     } else {
  //       setWFactor(0.84);
  //       setHFactor(0.95 * 0.8);
  //     }
  //     setWidth(window.innerWidth * wFactor);
  //     setHeight(window.innerHeight * hFactor * 0.5);
  //   }
  //   handleWindowResize();
  //   window.addEventListener("resize", handleWindowResize);

  //   return () => {
  //     window.removeEventListener("resize", handleWindowResize);
  //   };
  // }, [hFactor, wFactor]);

  return (
    <div style={{ background: "#fff" }}>
      {!props.onModal ? <Header selected={5} /> : null}

      <div style={{ paddingTop: "20px" }}>
        <AlertComponent
          show={showAlert}
          variant={alertVariant}
          text={alertText}
        />
        <LoadingSpinner show={showSpinner} />
      </div>
      <div
        className="content"
        style={{ padding: "20px", display: "flex", width: "100%" }}
      >
        <div
          className="container"
          style={{
            width: "100%",
            height: "100%",
            paddingBottom: "10px",
            // border: "2px solid red",
          }}
        >
          <div
            className="container"
            style={{
              border: "2px solid grey",
              marginRight: "40px",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <div className="row row-cols-1 row-cols-sm-2">
              <div className="col my-2">
                <input
                  type="text"
                  placeholder="Nombres"
                  onChange={handleFirstNameChange}
                  className="form-control"
                  value={firstName}
                />
              </div>
              <div className="col my-2">
                <input
                  type="text"
                  placeholder="Apellidos"
                  onChange={handleLastNameChange}
                  className="form-control"
                  value={lastName}
                />
              </div>
              <div className="col my-2">
                <input
                  type="text"
                  placeholder="Correo Electrónico"
                  onChange={handleEmailChange}
                  className="form-control"
                  value={email}
                />
              </div>
              <div className="col my-2">
                <input
                  type="text"
                  placeholder="Teléfono"
                  onChange={handlePhoneChange}
                  className="form-control"
                  value={phone}
                />
              </div>
              <div className="col-lg my-2">
                <textarea
                  placeholder="Mensaje"
                  onChange={handleMessageChange}
                  className="form-control"
                  rows={9}
                  value={message}
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div className="button" onClick={handleContactFormSubmit}>
                  Enviar
                </div>
              </div>
            </div>
          </div>
        </div>
        {!props.onModal ? (
          <div style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                height: window.innerHeight * 0.58,
                border: "2px solid grey",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <GMap />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ContactsRoute;
