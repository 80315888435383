import React, { useState, useEffect, useRef } from "react";

import Contacts from "../components/contacts";
import Header from "../components/header";
import LoadingSpinner from "../components/loadingSprinner";

import sellIcon from "../resources/sellsIcon.png";
import clientIcon from "../resources/clientsIcon.png";
import environmetIcon from "../resources/environmentIcon.png";
import aboutusLogo from "../resources/aboutusLogo.png";

import bannerImg from "../resources/aboutusImg1.png";

import { useBackend } from "../context/backendContext";

function HomeRoute() {
  const componentName = "HomeRoute";

  /* #region hook usage */
  const { getPrivateLabelBrands } = useBackend();
  /* #endregion */

  /* #region variables */
  const onLoad = useRef(true);
  const reRender = useRef(false);
  const [dbPending, setDbPending] = useState(true);
  const [privateLableBrands, setPrivateLabelBrands] = useState([]);
  /* #endregion */

  const renderPrivateLabelBrands = privateLableBrands.map((brandObj) => {
    return (
      <div
        // className="col"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // border: "2px solid red",
          padding: "10px",
        }}
      >
        <img
          src={"https://www.rymmao.com/" + brandObj.resource}
          alt={brandObj.name}
          width="120px"
        />
        {/* <div>{brandObj.name}</div> */}
      </div>
    );
  });

  useEffect(() => {
    async function uEgetPrivateLabelBrand() {
      try {
        const privateLableBrandsResult = await getPrivateLabelBrands();
        setPrivateLabelBrands(privateLableBrandsResult);
      } catch (e) {
        console.log(e);
      }
    }

    async function onComponentRender() {
      console.log(`on ${componentName} re-Render`);
      setDbPending(true);
      setDbPending(false);
    }

    async function onComponentLoad() {
      console.log(`on ${componentName} Load`);
      setDbPending(true);
      await uEgetPrivateLabelBrand();
      setDbPending(false);
    }
    //re-Render condition
    if (!onLoad.current) {
      if (reRender.current) {
        onComponentRender();
      } else {
        reRender.current = true;
      }
    }

    //onLoad condition
    if (onLoad.current) {
      //first render
      onComponentLoad();
      onLoad.current = false;
    }
  }, [getPrivateLabelBrands]);

  if (dbPending) {
    return (
      <React.Fragment>
        <div
          style={{
            height: "100%",
            // border: "2px solid red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner show={true} size="xl" />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div
      style={{
        background: "#fff",
        // border: "2px solid green"
      }}
    >
      <Header selected={0} />
      <div
        className="content"
        style={{
          // border: "2px solid red",
          width: "100%",
          // height: "400px",
          // margin: "10px",
          // marginRight: "10px",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <img src={bannerImg} width={"1500px"} alt="" />
      </div>
      {/* intro */}
      <div
        className="content"
        style={{
          display: "flex",
          // border: "2px solid green",
        }}
      >
        <div
          style={{
            textAlign: "justify",
            paddingTop: "40px",
            // border: "2px solid red",
          }}
        >
          <div>
            <p>
              Inició su trayectoria en el año 2009 con una visión clara:
              desarrollar productos cosméticos y dermocosméticos que combinan
              excelencia científica y calidad, cuyo fin es transformar el
              cuidado personal a través de la creación de productos innovadores.
              Lo que comenzó como un proyecto impulsado por la pasión por la
              belleza y la ciencia, pronto se convirtió en una marca reconocida
              por su compromiso con la calidad y la innovación.
            </p>
          </div>
          <div>
            <p>
              Desde nuestros inicios nos enfocamos en la investigación y
              desarrollo como pilares fundamentales. Hoy después de más de una
              década de constante evolución, y gracias a nuestra visión,
              esfuerzo, confianza de nuestros clientes, enfoque en la calidad y
              tecnología y el apoyo de un equipo apasionado, hemos crecido hasta
              convertirnos en líderes en la industria, marcando tendencia en el
              mercado cosmético y dermocosmético.
            </p>
          </div>
          {/* slogan final */}
          <div
            style={{
              // border: "2px solid red",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              // fontSize: "35px",
              // fontStyle: "italic",
            }}
            className="subtitle"
          >
            <p>
              {/* <strong> */}
              Cada producto refleja nuestra dedicación y compromiso con el
              bienestar integral.
              {/* </strong> */}
            </p>
          </div>
        </div>
      </div>

      {/* marcas maquiladas */}
      <div className="content" style={{ marginTop: "80px" }}>
        <div className="title">Marcas Maquiladas</div>
        <div style={{ textAlign: "justify", paddingBottom: "50px" }}>
          <p>
            Nuestro trabajo constante y enfoque nos han permitido ganar el
            reconocimiento y confianza de empresas destacadas a nivel nacional
            como son:
            <ul>
              <li>Femsa con sus cadenas de farmacias Fybeca y Sana Sana.</li>
              <li>
                Azzorti de Colombia con la producción perfumes para su venta por
                catálogo en diferentes países como Colombia, Ecuador, Perú,
                Bolivia y Guatemala.
              </li>
            </ul>
          </p>
        </div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
          {renderPrivateLabelBrands}
        </div>
      </div>
      {/* experiencia */}
      <div className="container content" style={{ paddingTop: "80px" }}>
        <div className="title">Nuestra experiencia</div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5 d-flex justify-content-between">
          <div>
            <div>
              <img src={sellIcon} alt="producstIcon" width={"60px"} />
            </div>
            <div>
              <p>
                50000+
                <br />
                Ventas por año
              </p>
            </div>
          </div>
          <div>
            <div>
              <img src={clientIcon} alt="clientstIcon" width={"60px"} />
            </div>
            <div>
              <p>
                200+
                <br />
                Clientes satisfechos
              </p>
            </div>
          </div>
          <div>
            <div>
              <img src={environmetIcon} alt="clientstIcon" width={"60px"} />
            </div>
            <div>
              <p>
                Resposabilidad
                <br />
                Ambiental
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeRoute;
