import React, { useState, useRef, useEffect } from "react";

import storeLogo from "../resources/logo.png";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import HeaderItem from "./headerItem";

import Dropdown from "../components/dropdown";

import { useNavigate } from "react-router-dom";
import { useBackend } from "../context/backendContext";

function Header(props) {
  const navigateTo = useNavigate();
  const [headerItems, setHeaderItems] = useState([]);
  const [backendPending, setBackendPending] = useState(true);
  const requestServer = useRef(true);
  const { getHeaderMenuItems } = useBackend();

  useEffect(() => {
    async function getHeaders() {
      try {
        const getHeaderResult = await getHeaderMenuItems();
        // console.log(getHeaderResult);
        setHeaderItems(getHeaderResult);
      } catch (error) {
        console.log(error.message);
      }
    }

    async function requestAll() {
      await getHeaders();
      setBackendPending(false);
    }

    if (requestServer.current) {
      requestAll();
      requestServer.current = false;
    }
  }, [getHeaderMenuItems]);

  const renderHeaderItems = headerItems.map((headerItem) => {
    if (headerItem.headermenuisdrop === "True") {
      return (
        <div key={headerItem.headermenuid}>
          <Dropdown
            headerMenuId={headerItem.headermenuid}
            selected={props.selected === headerItem.headermenuid ? true : false}
            caption={headerItem.headermenuvalue}
          />
        </div>
      );
    } else {
      return (
        <HeaderItem
          key={headerItem.headermenuid}
          // isMenu={headerItem.headermenuisdrop === "True" ? true : false}
          caption={headerItem.headermenuvalue}
          link={headerItem.headermenulink}
          selected={props.selected === headerItem.headermenuid ? true : false}
        />
      );
    }
  });

  if (backendPending) {
    return (
      <React.Fragment>
        <div className="header_navbar py-2" style={{ color: "transparent" }}>
          <h1>Loading...</h1>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Navbar
          expand="xl"
          variant="light"
          sticky="top"
          className="header_navbar"
          style={{
            // border: "2px solid cyan",
            background: "#fff",
          }}
        >
          {/* <Contacts/> */}
          <Navbar.Brand className="ms-2">
            <img
              src={storeLogo}
              alt="Store_logo"
              width={"200px"}
              onClick={() => {
                navigateTo("/");
              }}
              style={{
                cursor: "pointer",
                //  border:"2px solid red"
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarColapse" />
          <Navbar.Collapse id="navbarColapse">
            <Nav
              // className="d-flex flex-fill"
              style={{
                // border: "2px solid red",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "stretch",

                // height: "80px",
                // marginRight: "100px",
              }}
            >
              <div className="header_components">{renderHeaderItems}</div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Header;
