import React from "react";

import serviceImg1 from "../resources/service_img_1.jpeg";

function AsesoriaService() {
  return (
    <React.Fragment>
      <div
        className="content"
        style={{
          display: "flex",
          padding: "20px",
          paddingTop: "40px",
          paddingBottom: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="service-img">
          <img src={serviceImg1} width={"300px"} alt="" />
        </div>
        <div
          className="service-text"
          style={{
            textAlign: "justify",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          Transforma tu idea en un producto de éxito con nuestra experiencia. En
          Corporación Rymmao, brindamos asesoría integral en el desarrollo de
          productos cosméticos, desde su formulación hasta la comercialización,
          con un enfoque estratégico en la optimización financiera, para
          garantizar la viabilidad y el éxito en el mercado. Juntos llevaremos
          tu producto al mercado de manera efectiva y rentable.
        </div>
      </div>
    </React.Fragment>
  );
}

export default AsesoriaService;
