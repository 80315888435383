import React, { useState, useRef, useEffect } from "react";

import Button from "./button";
import crossIcon from "../resources/crossIcon.png";

function Modal({ children, height, show, onClose, top, left }) {
  const componentName = "modalComponent";
  const onLoad = useRef(true);
  const reRender = useRef(false);
  const [dbPending, setDbPending] = useState(true);

  const [displayModal, setDisplayModal] = useState(false);

  const handleModalCloseClick = () => {
    setDisplayModal(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    async function onComponentRender() {
      console.log(`on ${componentName} re-Render`, show);
      setDbPending(true);
      setDisplayModal(show);
      setDbPending(false);
    }

    async function onComponentLoad() {
      console.log(`on ${componentName} Load`, show);
      setDbPending(true);
      setDisplayModal(show);
      setDbPending(false);
    }
    //re-Render condition
    if (!onLoad.current) {
      if (reRender.current) {
        onComponentRender();
      } else {
        reRender.current = true;
      }
    }

    //onLoad condition
    if (onLoad.current) {
      //first render
      onComponentLoad();
      onLoad.current = false;
    }
  }, [show]);

  if (dbPending) {
    return null;
  }

  if (!displayModal) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          top: top ? top : "0px",
          left: left ? left : "0px",
          //   right: right ? right : "0px",
          //   bottom: bottom ? bottom : "0px",
          width: "100%",
          height: height + 86 + 36 + 5,
          zIndex: "1000",
          background:
            "rgba(255, 255, 255, 0.4)" /* Adjust the color and opacity as needed */,
          backdropFilter: "blur(6px)" /* Adjust the blur level as needed */,
        }}
        // hidden={displayModal}
      >
        <div
          className="d-flex justify-content-end align-items-center py-1"
          //   style={{ background: "green" }}
        >
          <Button
            buttonIcon={crossIcon}
            iconAlt={"return"}
            width="20px"
            buttonStyle={false}
            onClick={handleModalCloseClick}
            show={true}
            // block={showAlert}
            marginRight="10px"
          />
        </div>
        <div
          className="d-flex justify-content-center align-items-start"
          style={{
            // background: "white",
            height: "100vh",
          }}
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Modal;
