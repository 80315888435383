import React from "react";

import serviceImg1 from "../resources/service_img_1.jpeg";

function FullMaquilaService() {
  return (
    <React.Fragment>
      <div
        className="content"
        style={{
          display: "flex",
          padding: "20px",
          paddingTop: "40px",
          paddingBottom: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="service-img">
          <img src={serviceImg1} width={"300px"} alt="" />
        </div>
        <div
          className="service-text"
          style={{
            textAlign: "justify",
            // paddingLeft: "70px",
            // paddingRight: "125px",
          }}
        >
          <div>
            <p>
              Excelencia sin límites. Producción a tu medida. Creamos cosméticos
              de alta calidad, adaptándonos a tu visión y a la cantidad que
              necesitas. Desde lotes exclusivos hasta grandes volúmenes, cada
              producto refleja el compromiso y perfección que necesitas.
            </p>
          </div>

          <div>
            <strong>Servicios:</strong>
            <ul>
              <li>Desarrollo fórmula, personalizada o preexistente</li>
              <li>Obtención Notificaciones Sanitarias con el ente regulador</li>
              <li>Selección y suministro de insumos y materiales</li>
              <li>Producción mezclado y envasado con precisión y cuidado</li>
              <li>Etiquetado y acabado final con detalles impecables</li>
              <li>Pruebas de calidad</li>
            </ul>
          </div>
          <div
            className="subtitle"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Precisión y excelencia en cada detalle
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FullMaquilaService;
