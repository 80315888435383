import React from "react";

function Button(props) {
  if (props.show) {
    return (
      <div
        className="button"
        onClick={props.onClick}
        style={{ marginRight: props.marginRight ? props.marginRight : "0px" }}
      >
        <img
          src={props.buttonIcon}
          alt={props.iconAlt}
          width={props.width}
          // style={{ border: "2px solid red" }}
        />
        {props.value}
      </div>
    );
  }
}

export default Button;
